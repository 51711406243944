import classNames from 'classnames';
import { Children, cloneElement, isValidElement } from 'react';
import { useDiveContext } from '../../../../context';
import { List } from '../List';
import type { ListProps } from '../List.types';
import { listGroupHeaderCVA, listGroupItemsCVA } from './ListGroup.cva';
import type { ListGroupProps } from './ListGroup.types';

/**
 * ListGroup is used to wrap multiple List elements together
 *
 * @example
 * <ListGroup header="Header" description"Description">
 *  <ListGroup.Item title="Title 1" />
 *  <ListGroup.Item title="Title 2" />
 * </ListGroup>
 */
export function ListGroup({
  header,
  headerAs: Header = 'h2',
  description,
  children,
  className,
  'data-testid': dataTestId,
}: ListGroupProps): JSX.Element {
  const { isTv, device } = useDiveContext();

  const validElementsToBeDisplayed = Children.toArray(children).filter(
    isValidElement<ListProps>
  );

  return (
    <div
      data-testid={dataTestId}
      className={classNames('flex flex-col', className)}
    >
      {(!!header || !!description) && (
        <div
          className={classNames(
            'flex flex-col',
            isTv ? 'mb-dt-spacing-300' : 'mb-dt-spacing-100'
          )}
        >
          {header && (
            <Header className={listGroupHeaderCVA({ device })}>{header}</Header>
          )}
          {description && isTv && (
            <span
              className={classNames(
                [
                  'text-dt-theme-tv-text-list-list-group-subtitle font-dt-font-family-system',
                  'text-dt-font-size-30 leading-dt-font-line-height-36',
                ],
                {
                  'mt-dt-spacing-200': isTv,
                }
              )}
            >
              {description}
            </span>
          )}
        </div>
      )}
      <ul className="list-none m-dt-spacing-none p-dt-spacing-none">
        {validElementsToBeDisplayed.map((child, index) => {
          return (
            <li key={child.key || child.props.title} className="block w-full">
              {cloneElement(child, {
                className: classNames(
                  child.props.className,
                  listGroupItemsCVA({
                    device,
                    first: !index,
                    last: index === validElementsToBeDisplayed.length - 1,
                  })
                ),
              })}
            </li>
          );
        })}
      </ul>
      {description && !isTv && (
        <span
          className={classNames([
            'pt-dt-spacing-100 text-dt-theme-text-list-row-list-description font-dt-font-family-system',
            'text-dt-font-size-14 leading-dt-font-line-height-18',
          ])}
        >
          {description}
        </span>
      )}
    </div>
  );
}

ListGroup.Item = List;
