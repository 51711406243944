import type { ApiV2OnClick } from '@dce-front/hodor-types/api/v2/common/dto/definitions';
import type { ApiV2ContextualOfferSelector } from '@dce-front/hodor-types/api/v2/contextual_offer_page/definitions';
import type { ApiV2Selector } from '@dce-front/hodor-types/api/v2/episodes/definitions';
import type { ApiV2MoreInfosSelector } from '@dce-front/hodor-types/api/v2/more_infos/definitions';
import classNames from 'classnames/bind';
import type { ReactNode } from 'react';
import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { getDefaultSeasonIndex } from '../../helpers/getDefaultSeasonIndex';
import { HorizontalList } from '../HorizontalList/HorizontalList';
import { Linker as FallbackLinker } from '../Linker/Linker';
import styles from './SeasonsTabs.module.css';

const cx = classNames.bind(styles);

export const defaultRenderWrapperLinker = (
  onClick: ApiV2Selector['onClick'] | ApiV2MoreInfosSelector['onClick'],
  className: string,
  label?: string
): ReactNode => (
  <FallbackLinker
    data={{ mainOnClick: onClick }}
    objKey="onClick"
    className={className}
  >
    {label}
  </FallbackLinker>
);

export const defaultRenderBinder = (
  children: ReactNode
): JSX.Element | ReactNode => children;

export type SeasonsTabsProps = {
  /** ID for SeasonsTabs  */
  itemId: string;

  /** label for "season" */
  label: string;

  /** linker for button */
  renderWrapperLinker?: (
    onClick: ApiV2Selector['onClick'] | ApiV2MoreInfosSelector['onClick'],
    className: string,
    label?: string,
    seasonNumber?: number,
    navigateonfocus?: boolean
  ) => ReactNode;

  /** tv binder for onClick */
  renderWrapperBinder?: (children: ReactNode) => JSX.Element | ReactNode;

  /** seasons data from hodor */
  seasons?: (
    | ApiV2Selector
    | ApiV2MoreInfosSelector
    | ApiV2ContextualOfferSelector
  )[];

  /** Custom className */
  className?: string;

  /** If the component come from detail page  */
  isFromDetail?: boolean;

  /** Whether the device is a TV or not  */
  isTvDevice?: boolean;

  /** custom onClick */
  handleClick?: (
    onClickHodor: ApiV2OnClick | ApiV2MoreInfosSelector['onClick']
  ) => void;
};

export function SeasonsTabs({
  className,
  isFromDetail,
  isTvDevice,
  itemId,
  label: seasonI18nLabel,
  renderWrapperBinder = defaultRenderBinder,
  renderWrapperLinker = defaultRenderWrapperLinker,
  handleClick,
  seasons,
}: SeasonsTabsProps): JSX.Element | null {
  const { pathname } = useLocation();

  // Computes the default season index to focus
  const { activeSeasonIndex } = useMemo(
    () => getDefaultSeasonIndex(seasons || [], pathname),
    [seasons, pathname]
  );
  const seasonDescription = (seasons?.[activeSeasonIndex] as ApiV2Selector)
    ?.description;

  const SeasonsList = (
    <HorizontalList className={cx('seasonsList')} showControls={false}>
      {seasons?.map((season, seasonIndex) => {
        const { seasonNumber, onClick: onClickHodor } = season;
        const label = 'label' in season ? season.label : '';

        const seasonLabel =
          seasonNumber !== undefined
            ? (isTvDevice && `${seasonI18nLabel} ${seasonNumber}`) ||
              `S${seasonNumber}`
            : undefined;

        const indexedId: string = `${itemId}-${seasonIndex}`;
        /**
         * All case for handleClick should be deleted when dive seasonSelector is use in funnel
         */
        return handleClick ? (
          <button
            key={indexedId}
            className={cx('seasonsList__season', 'globalDetailSeasonButton', {
              'seasonsList__season--active': activeSeasonIndex === seasonIndex,
            })}
            tabIndex={0}
            data-tv-focusmode="attribute"
            type="button"
            onClick={() => handleClick(onClickHodor)}
            data-navigateonfocus={
              isTvDevice && activeSeasonIndex !== seasonIndex
            }
          >
            <span className={styles.seasonsList__label}>
              {label || seasonLabel}
            </span>
          </button>
        ) : (
          <li
            key={indexedId}
            className={cx('seasonsList__season', 'globalDetailSeasonButton', {
              'seasonsList__season--active': activeSeasonIndex === seasonIndex,
            })}
          >
            {onClickHodor &&
              renderWrapperLinker(
                onClickHodor,
                'globalDetailSeasonButton',
                label || seasonLabel,
                seasonNumber,
                isTvDevice && activeSeasonIndex !== seasonIndex
              )}
          </li>
        );
      })}
    </HorizontalList>
  );

  return seasons?.length ? (
    <div
      className={cx('seasonsTabs', className, {
        'seasonsTabs--detail': isFromDetail,
      })}
      data-testid="seasons-wrapper"
    >
      {isTvDevice ? renderWrapperBinder(SeasonsList) : SeasonsList}
      {seasonDescription && (
        <p
          className={cx('seasonsList__seasonDescription')}
          data-testid="season-description"
        >
          {seasonDescription}
        </p>
      )}
    </div>
  ) : null;
}
