import { cva } from 'class-variance-authority';

export const checkboxWrapperCVA = cva(
  'flex items-center w-auto h-auto font-dt-font-family-system',
  {
    variants: {
      disabled: {
        true: 'opacity-dt-opacity-50',
        false: undefined,
      },
    },
  }
);

export const checkboxInputCVA = cva(
  [
    'flex appearance-none size-full m-dt-spacing-none',
    'border-dt-theme-border-checkbox-checkbox border-solid border-dt-border-regular rounded-dt-radius-small',
  ],
  {
    variants: {
      checked: {
        true: 'bg-dt-theme-surface-checkbox-checkbox',
        false: undefined,
      },
      hasError: {
        true: 'border-dt-theme-border-checkbox-checkbox-error',
        false: undefined,
      },
      disabled: {
        true: 'cursor-not-allowed',
        false: 'cursor-pointer',
      },
    },
  }
);

export const checkboxCheckIndicatorCVA = cva(
  'pointer-events-none absolute top-0 left-0 fill-dt-theme-icon-checkbox-icon',
  {
    variants: {
      disabled: {
        true: 'cursor-not-allowed',
        false: 'cursor-pointer',
      },
    },
  }
);

export const checkboxLabelWrapper = cva(
  'flex self-end items-start dt-breakpoint-xs:items-center w-full',
  {
    variants: {
      variant: {
        vertical: 'flex-col dt-breakpoint-xs:flex-row',
        horizontal: 'flex-row',
      },
    },
  }
);

export const checkboxLabelContentCVA = cva(
  [
    'flex select-none',
    'text-dt-font-size-16 leading-dt-font-line-height-20 font-dt-font-weight-regular',
  ],
  {
    variants: {
      variant: {
        vertical: 'flex-col',
        horizontal: 'flex-row',
      },
      disabled: {
        true: 'cursor-not-allowed',
        false: 'cursor-pointer',
      },
    },
  }
);

export const checkboxSubLabelCVA = cva(
  'text-dt-theme-text-checkbox-checkbox-sublabel',
  {
    variants: {
      variant: {
        vertical: 'mt-dt-spacing-50',
        horizontal: undefined,
      },
    },
  }
);

export const checkboxActionableElementCVA = cva('flex', {
  variants: {
    variant: {
      vertical: [
        'ml-dt-spacing-none dt-breakpoint-xs:ml-auto ',
        'mt-dt-spacing-200 dt-breakpoint-xs:mt-dt-spacing-none dt-breakpoint-xs:pl-dt-spacing-200',
      ],
      horizontal: 'ml-auto',
    },
  },
});
