import type { ApiV2TechnicalInfos } from '@dce-front/hodor-types/api/v2/detail/spyro/definitions';
import { Accessibility } from './components/Accessibility/Accessibility';
import type { CustomParentalRating } from './components/ParentalRatings/ParentalRatings';
import { ParentalRatings } from './components/ParentalRatings/ParentalRatings';
import { Qualities } from './components/Qualities/Qualities';

export type CustomTechnicalInfos = Omit<
  ApiV2TechnicalInfos,
  'parentalRatings' | 'audio'
> & {
  parentalRatings?: CustomParentalRating[]; // Add `title?: string` for a11y
  audio?: ApiV2TechnicalInfos['audio'] | string[]; // Add string[] since PlaysetQuality !== SpyroAudio
};

export type TechnicalInfosProps = {
  technicalInfos: CustomTechnicalInfos;
  options?: {
    ignoreCSATousPublics?: boolean;
    closedCaptioningText?: string;
    audioDescriptionText?: string;
  };
};

/**
 * URLLogo Component
 *
 * @param technicalInfos                 Object content parentalRatings and video
 */
export function TechnicalInfos({
  technicalInfos,
  options,
}: TechnicalInfosProps): JSX.Element {
  const { parentalRatings, video, audio, closedCaptioning, audioDescription } =
    technicalInfos;
  const {
    ignoreCSATousPublics = true,
    closedCaptioningText,
    audioDescriptionText,
  } = options || {};

  return (
    <>
      {parentalRatings && (
        <ParentalRatings
          parentalRatings={parentalRatings}
          ignoreCSATousPublics={ignoreCSATousPublics}
        />
      )}
      {video && <Qualities qualities={video} type="video" bestIconOnly />}
      {audio && <Qualities qualities={audio} type="audio" bestIconOnly />}
      {(closedCaptioning || audioDescription) && (
        <Accessibility
          hasClosedCaptioning={closedCaptioning}
          hasAudioDescription={audioDescription}
          closedCaptioningText={closedCaptioningText}
          audioDescriptionText={audioDescriptionText}
        />
      )}
    </>
  );
}
