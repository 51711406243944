import { MediaImage } from '@canalplus/dive';
import type { ApiV2TvodPrice } from '@dce-front/hodor-types/api/v2/common/dto/tvod/defintions';
import classNames from 'classnames';
import {
  TechnicalInfos,
  type CustomTechnicalInfos,
} from '../../TechnicalInfos/TechnicalInfos';
import { OfferPrice } from '../OfferPrice/OfferPrice';
import styles from './OfferHeader.module.css';

export type OfferHeaderProps = {
  /** custom className */
  className?: string;

  /** contains URLLogoTitle and altLogoTitle */
  logo?: {
    URLLogoTitle: string;
    altLogoTitle: string;
  };

  /** display string title */
  title: string;

  /** display string subtitle */
  subtitle?: string;

  /** contains urlImage card */
  URLImage?: string;

  /** information from icon parental, audio... */
  technicalInfos?: CustomTechnicalInfos;
  /** label a11y for technicalInfos */
  technicalInfosA11y?: {
    closedCaptioningText: string;
    audioDescriptionText: string;
  };

  /** offerPrices data */
  prices?: ApiV2TvodPrice;
};

/**
 * OfferHeader Component
 *
 * This component is used create TVOD's funnel header
 */
export function OfferHeader({
  className,
  logo,
  title,
  subtitle,
  URLImage,
  technicalInfos,
  prices,
  technicalInfosA11y,
}: OfferHeaderProps): JSX.Element {
  const { closedCaptioningText, audioDescriptionText } =
    technicalInfosA11y || {};
  const isPaymentMeans = URLImage || false;
  const img = logo?.URLLogoTitle || URLImage;

  return (
    <div
      className={classNames(styles.offerHeader, className, {
        [styles['offerHeader--paymentMeans']]: isPaymentMeans,
        [styles['offerHeader--withoutSubtitle']]: !subtitle,
      })}
    >
      {img && (
        <div
          className={classNames({
            [styles.offerHeader__logo]: logo,
            [styles.offerHeader__image]: URLImage,
          })}
        >
          {logo && !isPaymentMeans && (
            <img src={logo.URLLogoTitle} alt={logo.altLogoTitle} />
          )}
          {URLImage && <MediaImage src={URLImage} alt="" />}
        </div>
      )}
      <div className={styles.offerHeader__textWrapper}>
        <div className={styles.offerHeader__title}>{title}</div>

        {(subtitle || technicalInfos) && (
          <div className={styles.offerHeader__subtitle}>
            {subtitle && <span>{subtitle}</span>}
            {technicalInfos && isPaymentMeans && (
              <span className={styles.offerHeader__technicalInfos}>
                <TechnicalInfos
                  technicalInfos={technicalInfos}
                  options={{
                    closedCaptioningText,
                    audioDescriptionText,
                  }}
                />
              </span>
            )}
          </div>
        )}
        {prices && (
          <OfferPrice className={styles.offerHeader__price} prices={prices} />
        )}
      </div>
    </div>
  );
}
