import { DEFAULT_SEASON_INDEX } from '@canalplus/mycanal-commons';
import type { ApiV2ContextualOfferSelector } from '@dce-front/hodor-types/api/v2/contextual_offer_page/definitions';
import type { ApiV2Selector } from '@dce-front/hodor-types/api/v2/episodes/definitions';
import type { ApiV2MoreInfosSelector } from '@dce-front/hodor-types/api/v2/more_infos/definitions';

type SeasonSelectorType = Array<
  ApiV2Selector | ApiV2MoreInfosSelector | ApiV2ContextualOfferSelector
>;

/**
 * Helper function that retrieves default season index from the seasons provided by Hodor, but if not exists, -1 is returned
 * @param seasonsSelector list of seasons
 * @returns a season index from the list of seasons or -1
 */
const getHodorSeasonIndex = (seasonsSelector: SeasonSelectorType): number =>
  seasonsSelector.findIndex((season) => season.default === true);

/**
 * Helper function that retrieves the season index from the list of seasons based on the one specified in the path, but if none specified, -1 is returned
 * @param seasonsSelector list of seasons
 * @param pathname path of the detail page
 * @returns a season index from the list of seasons or -1
 */
export const getPathnameSeasonIndex = (
  seasonsSelector: SeasonSelectorType,
  pathname: string
): number =>
  seasonsSelector.findIndex(
    (season) => season.onClick?.path && pathname.includes(season.onClick.path)
  );

/**
 * Helper function that retrieves from a list of seasons and pathname,
 * the season index that corresponds to the one specified in the path, but if none specified,
 * it returns the season index that corresponds to the one marked as default by Hodor, if not exists, it returns DEFAULT_SEASON_INDEX as ultimate fallback
 * @param seasonsSelector list of seasons
 * @param path path of the detail page
 * @returns a season index from the list of seasons and isSeasonTargetedByUrl which indicates if the url directly targets one of the seasons
 */
export const getDefaultSeasonIndex = (
  seasonsSelector: SeasonSelectorType,
  path: string
): { activeSeasonIndex: number; isSeasonTargetedByUrl: boolean } => {
  const pathnameIndex = getPathnameSeasonIndex(seasonsSelector, path);
  if (pathnameIndex !== -1) {
    return { activeSeasonIndex: pathnameIndex, isSeasonTargetedByUrl: true };
  }

  const hodorIndex = getHodorSeasonIndex(seasonsSelector);
  if (hodorIndex !== -1) {
    return { activeSeasonIndex: hodorIndex, isSeasonTargetedByUrl: false };
  }

  return {
    activeSeasonIndex: DEFAULT_SEASON_INDEX,
    isSeasonTargetedByUrl: false,
  };
};
