import { ApiV2PaymentMeansDetail } from '@dce-front/hodor-types/api/v2/tvod/payment_means/definitions';
import { useMemo } from 'react';
import { useIsTvDevice } from '../../../../../../helpers/hooks/useIsTvDevice';
import { FunnelInnerStep } from '../../../../../../templates/FunnelTvod/stores/constants';
import { useFunnelCurrentStep } from '../../../../../../templates/FunnelTvod/stores/funnel/hooks';

/**
 * usePaymentMeanTitle display template name
 * Specific name is created depending of detail for oD
 * @param detail template PaymentMeans
 * @returns template title
 */
export const usePaymentMeanTitle = (
  detail?: ApiV2PaymentMeansDetail
): string => {
  const { choosePaymentMeanTitle, finalizePaymentTitle } =
    detail?.pageTitles || {};
  const isTvDevice = useIsTvDevice();
  const currentStep = useFunnelCurrentStep();

  const isChoosePaymentStep = !currentStep.innerStep;
  const isEndPaymentStep = currentStep.innerStep === FunnelInnerStep.EndPayment;

  return useMemo(() => {
    if (choosePaymentMeanTitle && isChoosePaymentStep && isTvDevice) {
      // On TV, always display choosePaymentMeanTitle for ChoosePayment step displayTitle = choosePaymentMeanTitle;
      return choosePaymentMeanTitle;
    } else if (finalizePaymentTitle && (!isTvDevice || isEndPaymentStep)) {
      // On desktop, always display finalizePaymentTitle for PaymentMeans step, and only for EndPayment step on TV
      return finalizePaymentTitle;
    }
    return '';
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentStep?.innerStep]);
};
